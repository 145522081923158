/* eslint-disable no-unused-vars */
import { BathroomOption, BedroomOption } from '@src/interfaces/app/addProperty';
import { DocumentList } from '@src/interfaces/app/agent/addProperty';
import CreditCheck from '@src/assets/svg/creditCheck';
import Document from '@src/assets/svg/document';
import EmployeVerified from '@src/assets/svg/employeVerified';
import KidsPets from '@src/assets/svg/kidsPets';
import PhotoId from '@src/assets/svg/photoId';
import ReceiptPro from '@src/assets/svg/receiptPro';
import RenterHistory from '@src/assets/svg/rentelHistory';
import VerifiedFinancialsPlace from '@src/assets/svg/verifiedFinancialsPlace';

export enum DocumentListEnum {
  //PlaidVerifiedFinancials = 'plaid_verified_financials',
  PhotoID = 'photo_id',
  RentalHistory = 'rental_history',
  EmploymentVerification = 'employment_verification',
  CreditCheck = 'credit_check',
  HouseholdInformation = 'household_information',
}

export enum DocumentRequiredOtherListEnum {
  OtherRequiredDocuments = 'other_required_documents',
}

export enum OtherDocumentListEnum {
  // Paystubs = 'paystubs',
  BankStatements = 'bank_statements',
  // W2Forms = 'w2_form',
  TaxReturns = 'tax_returns',
}

export const AllDocumentsListEnum = {
  ...DocumentListEnum,
  ...DocumentRequiredOtherListEnum,
  ...OtherDocumentListEnum,
  Other: 'other',
  RenterUploaded: 'Renter Uploaded',
} as const;

export enum Utility {
  Gas = 'Gas',
  Water = 'Water',
  Sewage = 'Sewage',
  Garbage = 'Garbage',
  Electricity = 'Electricity',
  Internet = 'Internet',
  SatelliteTV = 'Satellite TV',
  Cable = 'Cable',
}

export enum EditUtility {
  gas = 'Gas',
  sewage = 'Sewage',
  electricity = 'Electricity',
  internet = 'Internet',
  water = 'Water',
  garbage = 'Garbage',
  cable = 'Cable',
  satellite_tv = 'Satellite TV',
}

export const bedroomOptions: BedroomOption[] = [
  { value: '0', label: 'Studio' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

export const bathroomOptions: BathroomOption[] = [
  { value: '1', label: '1' },
  { value: '1.5', label: '1.5' },
  { value: '2', label: '2' },
  { value: '2.5', label: '2.5' },
  { value: '3', label: '3' },
  { value: '3.5', label: '3.5' },
];

// it's using in the and Add-property in documents step
export const DocumentGroupList: DocumentList[] = [
  {
    id: DocumentListEnum.PhotoID,
    value: DocumentListEnum.PhotoID,
    label: 'Photo ID',
    icon: PhotoId,
    toolTipText:
      'Asks the applicant to upload a picture or file of their Photo ID.',
  },
  {
    id: DocumentListEnum.RentalHistory,
    value: DocumentListEnum.RentalHistory,
    label: 'Rental History',
    icon: RenterHistory,
    toolTipText:
      'Asks the applicant to provide the last two years of residence history.',
  },
  {
    id: DocumentListEnum.EmploymentVerification,
    value: DocumentListEnum.EmploymentVerification,
    label: 'Employment ',
    icon: EmployeVerified,
    toolTipText: `Asks the applicant to provide their employer's name, email, address, annual income, and upload 6 weeks of paystubs or any other supporting documents of their income.`,
  },
  {
    id: DocumentListEnum.CreditCheck,
    value: DocumentListEnum.CreditCheck,
    label: 'Credit Check',
    icon: CreditCheck,
    toolTipText:
      'The applicant will complete a full credit, background, and evictions check through Transunion.',
  },
  {
    id: DocumentListEnum.HouseholdInformation,
    value: DocumentListEnum.HouseholdInformation,
    label: 'Household Information',
    icon: KidsPets,
    toolTipText:
      'Asks the applicant how many people will be living in the home including children, any pets, and what kind of pets.',
  },
];

export const DocumentRequiredOther: DocumentList[] = [
  {
    id: DocumentRequiredOtherListEnum.OtherRequiredDocuments,
    value: DocumentRequiredOtherListEnum.OtherRequiredDocuments,
    label: 'Other Required Documents',
    icon: Document,
    toolTipText: '',
  },
];

//TODO: If we need the Paystubs or W2 Forms then we can add here
export const DocumentOtherList: DocumentList[] = [
  {
    id: OtherDocumentListEnum.BankStatements,
    value: OtherDocumentListEnum.BankStatements,
    label: 'Bank Statements',
    icon: VerifiedFinancialsPlace,
    toolTipText:
      'Asks the applicant to provide their most recent bank statements.',
  },
  {
    id: OtherDocumentListEnum.TaxReturns,
    value: OtherDocumentListEnum.TaxReturns,
    label: 'Tax Returns',
    icon: ReceiptPro,
    toolTipText: 'Asks the applicant to provide their most recent tax return.',
  },
];

/**
 * Merge the DocumentGroup and DocumentOther and adding the other key
 * */
export const AllDocumentsList: DocumentList[] = [
  ...DocumentGroupList,
  ...DocumentRequiredOther,
  ...DocumentOtherList,
  {
    id: AllDocumentsListEnum.Other,
    value: AllDocumentsListEnum.Other,
    label: '',
    icon: Document,
  },
];

export const FEE_TYPES = {
  ONE_TIME: 'ONE_TIME',
  RECURRING: 'RECURRING',
};
