const COMMON_CONSTANTS = {
  limit: 20,
};

const EMAIL_PATTERN = /^[\w\-.]+(\+\w+)?@([\w-]+\.)+[\w-]{2,}$/;

const DEFAULT_HEADER_HEIGHT = 78;
const UPLOAD_FILE_SIZE_LIMIT_FOR_RENTER = 15;

const SUPPORTED_IMAGE_FORMATS = ['.png', '.jpg', '.jpeg', '.webp'];
const SUPPORTED_PDF_FORMAT = ['.pdf'];

const ALLOWED_FORMATS_TO_UPLOAD_FILE = {
  'image/png': SUPPORTED_IMAGE_FORMATS,
  'image/webp': SUPPORTED_IMAGE_FORMATS,
  'image/jpeg': SUPPORTED_IMAGE_FORMATS,
  'image/jpg': SUPPORTED_IMAGE_FORMATS,
  'image/pjpeg': SUPPORTED_IMAGE_FORMATS,
  'image/jpe': SUPPORTED_IMAGE_FORMATS,
  'application/pdf': SUPPORTED_PDF_FORMAT,
};

const ALLOWED_FORMATS_TO_UPLOAD_IMAGES = {
  'image/png': SUPPORTED_IMAGE_FORMATS,
  'image/webp': SUPPORTED_IMAGE_FORMATS,
  'image/jpeg': SUPPORTED_IMAGE_FORMATS,
  'image/jpg': SUPPORTED_IMAGE_FORMATS,
  'image/pjpeg': SUPPORTED_IMAGE_FORMATS,
  'image/jpe': SUPPORTED_IMAGE_FORMATS,
};

const SIGNATURE_TYPES = {
  keyboard: 'keyboard',
  mouse: 'mouse',
};

const CREDIT_CHECK_AMOUNT = 40;
const NAME_PATTERN = /^[a-zA-Z0-9\s-'’.,]+$/;

export {
  COMMON_CONSTANTS,
  ALLOWED_FORMATS_TO_UPLOAD_FILE,
  ALLOWED_FORMATS_TO_UPLOAD_IMAGES,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_PDF_FORMAT,
  SIGNATURE_TYPES,
  DEFAULT_HEADER_HEIGHT,
  EMAIL_PATTERN,
  CREDIT_CHECK_AMOUNT,
  NAME_PATTERN,
  UPLOAD_FILE_SIZE_LIMIT_FOR_RENTER,
};
