/* eslint-disable no-unused-vars */
export enum APPLICATION_STATES_ENUM {
  IN_PROGRESS = 'IN_PROGRESS',
  APPLICATION_SUBMITTED = 'SUBMITTED',
  LEASE_SENT = 'LEASE_SENT',
  LEASE_UPLOADED = 'LEASE_UPLOADED',
  LEASE_SIGNED_APPLICANTS = 'LEASE_SIGNED_APPLICANTS',
  LEASE_SIGNED_LANDLORD = 'LEASE_SIGNED_LANDLORD',
  PAYMENTS_REQUESTED = 'PAYMENTS_REQUESTED',
  PAYMENTS_RECEIVED = 'PAYMENTS_RECEIVED',
  ACCEPTED = 'FINALIZED',
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
  UNAVAILABLE = 'UNAVAILABLE',
  IN_PROGRESS_UNAVAILABLE = 'IN_PROGRESS_UNAVAILABLE',
}

export enum CREDIT_CHECK_STATES_ENUM {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export enum TRANSUNION_STATES_ENUM {
  UNVERIFIED = 'UNVERIFIED',
  MANUAL_AUTHENTICATION = 'MANUAL_AUTHENTICATION',
  VERIFIED = 'VERIFIED',
}

// @typescript-eslint/no-duplicate-enum-values
export enum APPLICATION_STATES_LABELS_ENUM {
  APPLICATION_IN_PROGRESS = 'Application In Progress',
  APPLICATION_SUBMITTED = 'Application Submitted',
  SEND_LEASE = 'Send Lease',
  LEASE_SENT = 'Lease Sent',
  CONTRACT_SIGNED = 'Lease Signed',
  AWAITING_SIGNATURE = 'Awaiting Signature',
  AWAITING_SIGNATURE_LANDLORD = 'Awaiting Landlord Signature',
  AWAITING_SIGNATURE_APPLICANTS = 'Awaiting Applicants Signature',
  LANDLORD_SIGNATURE = 'Landlord Signature',
  LEASE_SIGNED = 'Lease Signed',
  APPLICANTS_SIGNED = 'Applicants Signed',
  LANDLORD_SIGNED = 'Landlord Signed',
  REQUEST_PAYMENT = 'Request Payment',
  PAYMENT_REQUESTED = 'Payment Requested',
  AWAITING_PAYMENT = 'Awaiting Payment',
  PAYMENTS_RECEIVED = '#PAYMENT# Received',
  APPLICATION_ACCEPTED = 'Close Deal',
  VERIFYING_APPLICATION = 'Reviewing Application',
  APPLICATION_VERIFIED = 'Application Verified',
  SIGN_LEASE = 'Sign Lease',
  VERIFYING_LEASE = 'Verifying Lease',
  LEASE_VERIFIED = 'Lease Verified',
  MAKE_PAYMENT = 'Make Payment',
  PAYMENT_PAID = '#PAYMENT# Paid',
  APPLICATION_APPROVAL = 'Move In!',
  CANCELLED = 'Application Cancelled',
  DENIED = 'Application Denied',
  UNAVAILABLE = 'Application Unavailable',
  MOVE_IN = 'Move In',
}

export enum APPLICATION_CO_APPLICANT_ENUM {
  INVITED = 'INVITED',
  IN_PROGRESS = 'ACCEPTED_AND_IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  SIGNED_WITHOUT_WEBHOOK = 'SIGNED_WITHOUT_WEBHOOK',
  SIGNED = 'SIGNED',
  REJECTED = 'REJECTED',
}

/**
 * Application states const from agent side.
 */
const AGENT_APPLICATION_INITIAL_STATES = [
  {
    label: APPLICATION_STATES_LABELS_ENUM.APPLICATION_SUBMITTED,
    value: APPLICATION_STATES_ENUM.APPLICATION_SUBMITTED,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: '',
  },
  {
    label: APPLICATION_STATES_LABELS_ENUM.SEND_LEASE,
    value: APPLICATION_STATES_ENUM.LEASE_SENT,
    isDisabledBtn: true,
    isCompleted: false,
    buttonText: 'Upload',
  },
  {
    label: APPLICATION_STATES_LABELS_ENUM.AWAITING_SIGNATURE,
    value: APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: '',
  },
  {
    label: APPLICATION_STATES_LABELS_ENUM.APPLICATION_ACCEPTED,
    value: APPLICATION_STATES_ENUM.ACCEPTED,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: 'Close!',
  },
];

/**
 * Application states const from agent side.
 */
const AGENT_WITH_LANDLORD_APPLICATION_INITIAL_STATES = [
  {
    label: APPLICATION_STATES_LABELS_ENUM.APPLICATION_SUBMITTED,
    value: APPLICATION_STATES_ENUM.APPLICATION_SUBMITTED,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: '',
  },
  {
    label: APPLICATION_STATES_LABELS_ENUM.SEND_LEASE,
    value: APPLICATION_STATES_ENUM.LEASE_SENT,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: 'Upload',
  },
  {
    label: APPLICATION_STATES_LABELS_ENUM.AWAITING_SIGNATURE_APPLICANTS,
    value: APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: '',
  },

  {
    label: APPLICATION_STATES_LABELS_ENUM.LANDLORD_SIGNATURE,
    value: APPLICATION_STATES_ENUM.LEASE_SIGNED_LANDLORD,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: '',
  },
  {
    label: APPLICATION_STATES_LABELS_ENUM.APPLICATION_ACCEPTED,
    value: APPLICATION_STATES_ENUM.ACCEPTED,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: 'Close!',
  },
];

const RENTER_APPLICATION_INITIAL_STATES = [
  {
    label: APPLICATION_STATES_LABELS_ENUM.APPLICATION_IN_PROGRESS,
    value: APPLICATION_STATES_ENUM.IN_PROGRESS,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: '',
  },
  {
    label: APPLICATION_STATES_LABELS_ENUM.APPLICATION_SUBMITTED,
    value: APPLICATION_STATES_ENUM.APPLICATION_SUBMITTED,
    isCompleted: false,
    buttonText: 'Continue',
  },
  {
    label: APPLICATION_STATES_LABELS_ENUM.VERIFYING_APPLICATION,
    value: APPLICATION_STATES_ENUM.LEASE_SENT,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: 'Modify',
  },
  {
    label: APPLICATION_STATES_LABELS_ENUM.SIGN_LEASE,
    value: APPLICATION_STATES_ENUM.LEASE_SIGNED_APPLICANTS,
    isDisabledBtn: true,
    isCompleted: false,
    buttonText: 'Sign',
  },
  {
    label: APPLICATION_STATES_LABELS_ENUM.APPLICATION_APPROVAL,
    value: APPLICATION_STATES_ENUM.ACCEPTED,
    isDisabledBtn: false,
    isCompleted: false,
    buttonText: '',
  },
];

export {
  AGENT_APPLICATION_INITIAL_STATES,
  AGENT_WITH_LANDLORD_APPLICATION_INITIAL_STATES,
  RENTER_APPLICATION_INITIAL_STATES,
};
