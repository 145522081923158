import React from 'react';

const EmployeVerified = (props: object) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9984 5.967C14.9484 5.95866 14.8901 5.95866 14.8401 5.967C13.6901 5.92533 12.7734 4.98366 12.7734 3.81699C12.7734 2.62533 13.7318 1.66699 14.9234 1.66699C16.1151 1.66699 17.0734 2.63366 17.0734 3.81699C17.0651 4.98366 16.1484 5.92533 14.9984 5.967Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1422 12.0338C15.2839 12.2255 16.5422 12.0255 17.4255 11.4338C18.6005 10.6505 18.6005 9.36713 17.4255 8.58379C16.5339 7.99213 15.2589 7.79212 14.1172 7.99212"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.97344 5.967C5.02344 5.95866 5.08177 5.95866 5.13177 5.967C6.28177 5.92533 7.19844 4.98366 7.19844 3.81699C7.19844 2.62533 6.24011 1.66699 5.04844 1.66699C3.85677 1.66699 2.89844 2.63366 2.89844 3.81699C2.90677 4.98366 3.82344 5.92533 4.97344 5.967Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83255 12.0338C4.69088 12.2255 3.43255 12.0255 2.54922 11.4338C1.37422 10.6505 1.37422 9.36713 2.54922 8.58379C3.44089 7.99213 4.71588 7.79212 5.85755 7.99212"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99844 12.1916C9.94844 12.1833 9.8901 12.1833 9.8401 12.1916C8.6901 12.1499 7.77344 11.2083 7.77344 10.0416C7.77344 8.84994 8.73177 7.8916 9.92344 7.8916C11.1151 7.8916 12.0734 8.85827 12.0734 10.0416C12.0651 11.2083 11.1484 12.1583 9.99844 12.1916Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.57656 14.8168C6.40156 15.6001 6.40156 16.8835 7.57656 17.6668C8.9099 18.5585 11.0932 18.5585 12.4266 17.6668C13.6016 16.8835 13.6016 15.6001 12.4266 14.8168C11.1016 13.9335 8.9099 13.9335 7.57656 14.8168Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmployeVerified;
