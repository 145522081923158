/* eslint-disable no-unused-vars */
import { apiPropertyItem } from '@src/interfaces/api/publicProperties';
import { Documents } from '@src/interfaces/api/userProfile';
import { Step } from '@src/interfaces/components/wizard';
import { Amenity } from '@src/interfaces/profile';

export enum AllApplicationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  LEASE_SENT = 'LEASE_SENT',
  LEASE_UPLOADED = 'LEASE_UPLOADED',
  LEASE_SIGNED_APPLICANTS = 'LEASE_SIGNED_APPLICANTS',
  PAYMENTS_RECEIVED = 'PAYMENTS_RECEIVED',
  FINALIZED = 'FINALIZED',
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
}

export enum ApplicationStatus {
  INVITED = 'INVITED',
  REJECTED = 'REJECTED',
  ACCEPTED_AND_IN_PROGRESS = 'ACCEPTED_AND_IN_PROGRESS',
  PAID = 'PAID',
  LOCKED_IN = 'LOCKED_IN',
  SIGNED = 'SIGNED',
  COMPLETED = 'COMPLETED',
}

enum RenterRentalHistoryTypeEnum {
  RENTAL = 'RENTAL',
  OWNED = 'OWNED',
}

export enum SORT_OPTIONS {
  NEWEST_TO_OLDEST = 'NEWEST_TO_OLDEST',
  OLDEST_TO_NEWEST = 'OLDEST_TO_NEWEST',
  INCOME_LOW_TO_HIGH = 'INCOME_LOW_TO_HIGH',
  INCOME_HIGH_TO_LOW = 'INCOME_HIGH_TO_LOW',
}
export enum GET_SORT_APPLICATION_LABLE_ENUM {
  NEWEST_TO_OLDEST = 'Newest to Oldest',
  OLDEST_TO_NEWEST = 'Oldest to Newest',
  INCOME_LOW_TO_HIGH = 'Income Low to High',
  INCOME_HIGH_TO_LOW = 'Income High to Low',
}

interface GeneralInformation {
  property: apiPropertyItem;
  cumulative_application_file: null | string;
  uploaded_lease: null | string;
  docusign_global_identifier: null | string;
  landlord_email: null | string;
  landlord_signed: boolean;
  share_inprogress_application_with_agent?: boolean;
}

export interface StatusInformation {
  status:
    | AllApplicationStatus.IN_PROGRESS
    | AllApplicationStatus.SUBMITTED
    | AllApplicationStatus.LEASE_SENT
    | AllApplicationStatus.LEASE_SIGNED_APPLICANTS
    | AllApplicationStatus.PAYMENTS_RECEIVED
    | AllApplicationStatus.FINALIZED
    | AllApplicationStatus.CANCELLED
    | AllApplicationStatus.DENIED;
}

export interface SalaryInformation {
  reported_salary?: number | null;
  determined_income?: number | null;
  plaid_verification_public_token?: string | null;
}

interface Property {
  nearby_parks: number | null;
  school_rankings: number | null;
  points_of_interest: number | null;
  commute_time: number | null;
}

interface EmploymentInformation {
  time_at_work: string | null;
  location: string | null;
  length_of_commute: number | null; //
  commute_method: string | null;
}

interface HouseholdInformation {
  total_occupants: number;
  small_dogs: number;
  medium_dogs: number;
  large_dogs: number;
  cats: number;
  other_pets: number;
}

interface RentalHistories {
  id: string;
  type: RenterRentalHistoryTypeEnum;
  landlord_name: string | null;
  landlord_email: string | null;
  landlord_phone_number: string | null;
  start_date: string;
  end_date: string;
  current_address: string;
  still_living_here: boolean;
  mortgage: number | null;
  rent_payment: number | null;
}

interface Renter {
  id: string;
  general_information: {
    first_name: string;
    middle_name: string;
    last_name: string;
    verified: boolean;
    password: string;
  };
  renter_information: {
    financial_information: {
      credit_information: {
        reported_credit: number;
        fico_score: number | null;
        transunion_screening_id: string | null;
        report_file: string | null;
        expiry_date: string | null;
        transunion_status: string | null;
      };
      salary_information: SalaryInformation | null;
      employment_information: EmploymentInformation | null;
    };
    neighborhood_preference_information: Property | null;
    amenity_information: {
      amenities: Amenity[];
    };
    rental_histories: RentalHistories[] | null;
    household_information: HouseholdInformation | null;
  };
}

export interface Applicant {
  renter: Renter;
  email: string;
  id: string;
  is_primary_applicant: boolean;
  is_guarantor: boolean;
  documents: Documents[];
  status: ApplicationStatus;
  application_file: string | null;
  docusign_applicant_identifier: string | null;
  cumulative_report: string | null;
  isDisabled?: boolean;
  credit_bypassed?: boolean;
}

export interface AllApplication {
  id: string;
  general_information: GeneralInformation;
  status_information: StatusInformation;
  applicant_information: {
    applicants: Applicant[];
  };
  creation_date: string;
  last_update: string;
}

export interface AllApplications extends AllApplication {
  steps: { applicant: Applicant; step: Step[] }[];
}
