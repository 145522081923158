import { createContext } from 'react';

export interface LoaderContextProps {
  isLoading: boolean;
  loaderType: string;
  // eslint-disable-next-line no-unused-vars
  showLoader: (type?: string) => void;
  hideLoader: () => void;
}

const LoaderContext = createContext<LoaderContextProps | null>(null);

export default LoaderContext;
