import React from 'react';

function PhotoId(props: object) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 10.2158H9.33833C8.90499 10.2158 8.49333 10.3837 8.19 10.687L7.26374 11.6024C6.84124 12.0195 6.15334 12.0195 5.73084 11.6024L4.80458 10.687C4.50125 10.3837 4.08417 10.2158 3.65625 10.2158H3.25C2.35083 10.2158 1.625 9.49534 1.625 8.607V2.692C1.625 1.80367 2.35083 1.08325 3.25 1.08325H9.75C10.6492 1.08325 11.375 1.80367 11.375 2.692V8.60159C11.375 9.48992 10.6492 10.2158 9.75 10.2158Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.53905 4.84736C6.51739 4.84736 6.48487 4.84736 6.45779 4.84736C5.88904 4.8257 5.43945 4.36528 5.43945 3.79111C5.43945 3.20611 5.91071 2.73486 6.49571 2.73486C7.08071 2.73486 7.55197 3.21153 7.55197 3.79111C7.55738 4.36528 7.1078 4.83111 6.53905 4.84736Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00906 6.47778C4.28865 6.95987 4.28865 7.74528 5.00906 8.22737C5.82698 8.77445 7.17031 8.77445 7.98823 8.22737C8.70865 7.74528 8.70865 6.95987 7.98823 6.47778C7.17031 5.93612 5.8324 5.93612 5.00906 6.47778Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PhotoId;
