/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse, Canceler } from 'axios';

import { API_BASE_URL } from '@src/constants/urls';

import { getItem, getUserId, isLoggedIn, setItem } from './localStorage';
import { ErrorResponse, HelperParams } from '@src/interfaces/utils/http';
import { showErrorMsg } from './notifications';
import {
  AUTH_MSG,
  ERROR_MESSAGES,
  VALIDATION_MESSAGES,
} from '@src/constants/messages';
import { isObject } from 'lodash';
import { ZEME_USER_DATA } from '@src/constants/localStorage';
import { SessionObj } from '@src/interfaces/utils/localStorage';
import { APP_ROUTE } from '@src/constants/routes';

/**
 * Cancel Token
 */
const { CancelToken } = axios;

let isRefreshing = false;
let refreshSubscribers: any = [];

/**
 * Use to cancel Http Requests
 */
let cancelHttpTokens: Canceler[] = [];

/**
 * Helper Params used in Request
 */
const HELPER_PARAMS: HelperParams = {
  callback: null,
  hideError: false,
  headers: {
    Accept: 'application/json',
  },
};

function onRefreshed(token: string) {
  refreshSubscribers.map((cb: any) => cb(token));
}

function addRefreshSubscriber(callback: any) {
  refreshSubscribers.push(callback);
}

/**
 * Axios instance for all API requests
 */
const appAxios = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

// Refresh Token Interceptor
appAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;

    // Check if the error status is 401 (unauthorized) and the error is not a refresh token request
    if (
      originalRequest.url !== '/users/identify' &&
      isLoggedIn() &&
      response &&
      response.status === 401 &&
      !originalRequest._retry
    ) {
      // Prevent multiple token refresh requests
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refreshAxios = axios.create({
            baseURL: API_BASE_URL,
            withCredentials: true,
          });
          const rs = await refreshAxios.post(
            `${API_BASE_URL}/users/refresh/`,
            {}
          );

          const userData = getItem<SessionObj | null>(ZEME_USER_DATA);
          if (userData != null) {
            userData.refresh_token = rs.data.refresh_token;
            userData.access_token = rs.data.access_token;
            setItem(ZEME_USER_DATA, userData);
          }

          isRefreshing = false;
          onRefreshed(rs.data.access_token);
          refreshSubscribers = [];
        } catch (refreshError) {
          isRefreshing = false;
          refreshSubscribers = [];
          showErrorMsg(AUTH_MSG.sessionExpired);
          localStorage.clear();
          window?.location?.replace(APP_ROUTE.LOGIN);
          return response;
        }
      }

      // Add failed request to subscribers queue
      return new Promise((resolve) => {
        addRefreshSubscriber((token: any) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }

    if ((response && response?.data?.error) || (response && response?.status)) {
      return Promise.reject(error);
    }

    return response;
  }
);

/**
 * Get Common Headers for Request
 * @param url
 * @param additionalHeaders
 */
export const getCommonHeaders = (
  url: string,
  additionalHeaders: Record<string, string> = {}
): Record<string, string> => {
  try {
    const headers: Record<string, string> = {
      Accept: 'application/json',
      /* Additional Headers */
      ...additionalHeaders,
    };

    return headers;
  } catch (e) {
    return {};
  }
};

/**
 * Perform a GET request.
 *
 * @param url - The URL for the PATCH request.
 * @param HelperParams - Additional helper parameters.
 * @returns A Promise that resolves to the response data.
 */
export const httpGet = async (
  url: string,
  { callback, headers, hideError }: HelperParams = HELPER_PARAMS
): Promise<any> => {
  try {
    if (!headers) ({ headers } = HELPER_PARAMS);

    return appAxios
      .get(url, {
        headers: getCommonHeaders(url, headers),
        cancelToken: new CancelToken((c) => {
          cancelHttpTokens.push(c);
          if (callback) callback(c);
        }),
      })
      .then(httpHandleResponse)
      .catch((err) => httpHandleError(err, { hideError }));
  } catch (e) {
    console.error('-- HTTP GET -- ', e);
    return Promise.reject({});
  }
};

/**
 * Post Request
 * @param url
 * @param params
 * @param HelperParams
 */
export const httpPost = (
  url: string,
  params: any,
  {
    callback,
    headers,
    onUploadProgress,
    hideError,
  }: HelperParams = HELPER_PARAMS
): Promise<any> => {
  try {
    if (!headers) ({ headers } = HELPER_PARAMS);

    return appAxios
      .post(url, params, {
        onUploadProgress,
        headers: getCommonHeaders(url, headers),
        cancelToken: new CancelToken((c) => {
          cancelHttpTokens.push(c);
          if (callback) callback(c);
        }),
      })
      .then(httpHandleResponse)
      .catch((err) => httpHandleError(err, { hideError }));
  } catch (e) {
    console.error('-- HTTP POST -- ', e);
    return Promise.reject({});
  }
};

/**
 * Perform a PUT request.
 *
 * @param url - The URL for the PATCH request.
 * @param params - The parameters for the PATCH request.
 * @param HelperParams - Additional helper parameters.
 * @returns A Promise that resolves to the response data.
 */
export const httpPut = (
  url: string,
  params: any,
  { callback, headers }: HelperParams = HELPER_PARAMS
): Promise<any> => {
  try {
    if (!headers) ({ headers } = HELPER_PARAMS);

    return appAxios
      .put(url, params, {
        headers: getCommonHeaders(url, headers),
        cancelToken: new CancelToken((c) => {
          cancelHttpTokens.push(c);
          if (callback) callback(c);
        }),
      })
      .then(httpHandleResponse)
      .catch(httpHandleError);
  } catch (e) {
    console.error('-- HTTP PUT -- ', e);
    return Promise.reject({});
  }
};

/**
 * Perform a PATCH request.
 *
 * @param url - The URL for the PATCH request.
 * @param params - The parameters for the PATCH request.
 * @param HelperParams - Additional helper parameters.
 * @returns A Promise that resolves to the response data.
 */
export const httpPatch = (
  url: string,
  params: any,
  { callback, headers }: HelperParams = HELPER_PARAMS
): Promise<any> => {
  try {
    if (!headers) ({ headers } = HELPER_PARAMS);

    return appAxios
      .patch(url, params, {
        headers: getCommonHeaders(url, headers),
        cancelToken: new CancelToken((c) => {
          cancelHttpTokens.push(c);
          if (callback) callback(c);
        }),
      })
      .then(httpHandleResponse)
      .catch(httpHandleError);
  } catch (e) {
    console.error('-- HTTP PATCH -- ', e);
    return Promise.reject({});
  }
};

/**
 * Perform a PATCH request.
 *
 * @param url - The URL for the PATCH request.
 * @param HelperParams - Additional helper parameters.
 * @returns A Promise that resolves to the response data.
 */
export const httpDelete = (
  url: string,
  params?: any,
  { callback, headers }: HelperParams = HELPER_PARAMS
): Promise<any> => {
  try {
    if (!headers) ({ headers } = HELPER_PARAMS);

    return appAxios
      .delete(url, {
        data: params,
        headers: getCommonHeaders(url, headers),
        cancelToken: new CancelToken((c) => {
          cancelHttpTokens.push(c);
          if (callback) callback(c);
        }),
      })
      .then(httpHandleResponse)
      .catch(httpHandleError);
  } catch (e) {
    console.error('-- HTTP DELETE -- ', e);
    return Promise.reject({});
  }
};

/**
 * Handle Success Response
 */
export const httpHandleResponse = (res: AxiosResponse): object | null => {
  cancelHttpTokens = [];

  if (!res) return Promise.reject(null);

  /*handle any specific cases here*/

  return Promise.resolve(res.data);
};

// const handleRefresh = async (error: any) => {
//   const originalConfig = error.config;

//   console.log(originalConfig, error);

//   if (originalConfig.url !== '/users/identify' && error.response) {
//     if (!originalConfig._retry) {
//       originalConfig._retry = true;
//       try {
//         originalConfig._retry = true;
//         const rs = await axios.post(`${API_BASE_URL}/users/refresh/`, {
//           refresh_token: getRefreshToken(),
//         });
//         const userData = getItem<SessionObj | null>(ZEME_USER_DATA);
//         if (userData != null) {
//           userData.refresh_token = rs.data.refresh_token;
//           userData.access_token = rs.data.access_token;
//           setItem(ZEME_USER_DATA, userData);
//         }
//         return appAxios(originalConfig);
//       } catch (_error) {
//         showErrorMsg(AUTH_MSG.sessionExpired);
//         localStorage.clear();
//         window?.location?.replace(APP_ROUTE.LOGIN);
//       }
//     }
//   } else {
//     const xhr = error.request;
//     let err: ErrorResponse = {};
//     if (xhr.response) err = extractJSON(xhr.response);
//     if (err?.response?.status !== 401)
//       showErrorMsg(err.error || AUTH_MSG.sessionExpired);
//   }

//   return null;
// };

/**
 * Handle Success Response
 */
export const httpHandleError = (
  error: any,
  { hideError }: { hideError?: boolean } = {}
): object | null => {
  try {
    if (hideError) return Promise.reject(error);

    if (!error) return Promise.reject({});

    /* Handle Cancel Request */
    cancelHttpTokens = [];
    const xhr = error.request;
    let err: ErrorResponse = {};
    if (xhr.response) err = extractJSON(xhr.response);

    /*handle different error codes here right now handled only 401 and 404*/
    if (xhr) {
      switch (xhr.status) {
        case 404:
          showErrorMsg(err.detail);
        case 409:
          if (
            isObject(err) &&
            Object.keys(err).length > 0 &&
            String(Object.keys(err)[0]) === String(getUserId())
          )
            showErrorMsg(VALIDATION_MESSAGES.requiredRequestedDocuments);
          break;
        case 400:
          if (err.message) {
            showErrorMsg(err.message);
            break;
          }

          const errorMessage: string[] = Object.hasOwn(err, 'error')
            ? Object.keys(err)
                .map((value) => {
                  return value === 'error' && err[value];
                })
                .filter((value) => value)
            : Object.values(err).reduce((acc, value) => {
                if (Array.isArray(value)) {
                  return acc.concat(value);
                }
                if (typeof value === 'object') {
                  return acc.concat(Object.values(value).flat());
                }
                return acc;
              }, []);
          errorMessage.length ? showErrorMsg(errorMessage[0]) : showErrorMsg();
          break;
        case 500:
          showErrorMsg(ERROR_MESSAGES.internalError);
          break;
        default:
      }
    }
    return Promise.reject(error);
  } catch (e) {
    console.error('-- HTTP HANDLE ERROR -- ', e);
    return Promise.reject({});
  }
};

/**
 * Cancel Http Request
 */
export const httpCancel = (): void => {
  try {
    cancelHttpTokens.forEach((cancel) => cancel());
    cancelHttpTokens = [];
  } catch (e) {
    cancelHttpTokens = [];
  }
};

/**
 * Extract JSON Response
 */
export const extractJSON = (json: string): any => {
  try {
    const data = JSON.parse(json);
    if (typeof data == 'object' && data !== null) return data;
  } catch (e) {
    return {};
  }
  return {};
};
