import React from 'react';

function CreditCheck(props: object) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.9293 10.1682H17.0293V10.0682V9.06061C17.0293 4.56061 13.414 0.9 8.96465 0.9C4.51532 0.9 0.9 4.56061 0.9 9.06061V10.0682V10.1682H1H16.9293ZM16.1656 9.06061V9.2965H1.76368V9.06061C1.76368 5.04246 4.99724 1.77168 8.96465 1.77168C12.9321 1.77168 16.1656 5.04246 16.1656 9.06061Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        d="M11.9889 5.86087L11.9177 5.78888L11.8466 5.86087L9.59684 8.13775L9.52739 8.20804L9.59684 8.27832L10.0662 8.75333L10.1373 8.82532L10.2085 8.75333L12.4582 6.47645L12.5277 6.40616L12.4582 6.33587L11.9889 5.86087Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        d="M14.9392 9.83227H15.0392V9.73227V9.06059C15.0392 5.67308 12.3158 2.91514 8.96567 2.91514C5.61559 2.91514 2.89219 5.67308 2.89219 9.06059V9.73227V9.83227H2.99219H3.65587H3.75587V9.73227V9.06059C3.75587 6.15075 6.09338 3.78681 8.96567 3.78681C11.838 3.78681 14.1755 6.15075 14.1755 9.06059V9.73227V9.83227H14.2755H14.9392Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        d="M10.6232 9.83244H10.7232V9.73244V9.39666C10.7232 8.41469 9.93701 7.61729 8.96395 7.61729C7.99088 7.61729 7.20469 8.41469 7.20469 9.39666V9.73244V9.83244H7.30469H7.96837H8.06837V9.73244V9.39666C8.06837 8.89654 8.47268 8.48908 8.96395 8.48908C9.45521 8.48908 9.85953 8.89654 9.85953 9.39666V9.73244V9.83244H9.95953H10.6232Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        d="M14.3638 3.46121L14.2927 3.38922L14.2216 3.46121L12.8136 4.88623L12.7442 4.95653L12.8136 5.02681L13.283 5.50169L13.3541 5.57367L13.4253 5.50169L14.8332 4.07679L14.9026 4.0065L14.8332 3.93622L14.3638 3.46121Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        d="M3.71041 3.4595L3.63927 3.38751L3.56814 3.4595L3.09879 3.93451L3.02934 4.00479L3.09879 4.07508L4.50672 5.49998L4.57786 5.57197L4.64899 5.49998L5.11834 5.02497L5.18779 4.95468L5.11834 4.8844L3.71041 3.4595Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        d="M8.63281 1.23569H8.53281V1.33569V3.35084V3.45084H8.63281H9.29661H9.39661V3.35084V1.33569V1.23569H9.29661H8.63281Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </svg>
  );
}

export default CreditCheck;
