const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
const GOOGLE_MAP_URL = 'https://www.google.co.in/maps/preview';
const GOOGLE_PLACE_API =
  'https://maps.googleapis.com/maps/api/place/autocomplete/json';

const AUTH_API_URLS = {
  login: '/users/identify/',
  logout: '/users/logout/',
  REGISTER_AGENT: '/users/agents/',
  REGISTER_RENTER: '/users/renters/',
  REGISTER_LANDLORD: '/users/landlords/',
  DELETE_USER: '/accounts/delete/',
  EDIT_RENTER: '/users/renters/#RENTER_ID#/',
  EDIT_AGENT: '/users/agents/#AGENT_ID#/',
  EDIT_LANDLORD: '/users/landlords/#LANDLORD_ID#/',
  CHECK_EMAIL: '/users/email-check/',
  CHECK_PHONE: '/users/phone-check/',
  SEND_OTP: '/accounts/send_otp_phone_number/',
  VERIFY_OTP: '/accounts/verify_otp_phone_number/',
  UPDATE_PHONE_NUMBER: '/users/phone_number_update/',
  GET_SUBSCRIPTION_PLANS: '/users/subscription_plans/',
  APPLY_COUPON: '/users/subscription/apply_coupon/',
  CREATE_AGENT_SUBSCRIPTION: '/users/subscriptions/',
  CANCEL_AGENT_SUBSCRIPTION: '/users/subscription/cancel/',
  GET_UNLIMITED_ACCESS_TOKEN: '/users/verify/delimit_token/',
  GET_FAIR_HOUSING_TEMPLATE: '/users/fairhousing/',
  LOGIN_WITH_GOOGLE: '/users/google/sign_in/',
  LOGIN_WITH_APPLE: '/users/apple/sign_in/',
};

const PLAID_URLS = {
  GENERATE_TOKEN: '/users/plaidlink/#ID#/',
};

const PROPERTIES_API_URL = {
  PUBLIC_PROPERTIES: '/properties/public/',
  AGENT_PROPERTIES: '/properties/public/listed_by/',
  PROPERTY_PREVIEW_BEFORE_POSTING: '/properties/preview/',
  PROPERTY_ID: `/properties/public/#ID#/`,
  MODIFY_PROPERTY_DOCUMENT: `/properties/public/#ID#/documents/modify/`,
  MAP_DATA: '/properties/public/map',
  OPEN_PROPERTY: '/properties/public/#ID#/open/',
  CLOSE_PROPERTY: '/properties/public/#ID#/close/',
  GET_ALL_SAVE_PROPERTY: '/savesrenter/',
  POST_RENTER_SAVE_SYNC: '/savesrenter/sync/',
  DELETE_RENTER_SAVE_SYNC: '/savesrenter/',
  SEARCH_PROPERTY: '/users/place/search/',
  AGENT_TEMPLATE_RETREIVE: '/users/agents/property/template/',
  AGENT_CLOSE_PROPERTY: '/properties/public/#ID#/finalize/',
};

const APPLICATIONS_API_URL = {
  IDENTIFY_PROPERTY_ASSOCIATE_WITH_RENTER:
    '/applications/public/associated_with_renter/#RENTER_ID#/',
  CREATE_APPLICATION: '/applications/public/',
  IDENTIFY_PROPERTY_ASSOCIATE_WITH_AGENT:
    '/applications/public/for_agent/#AGENT_ID#/for_property/#PROPERTY_ID#/',
  VERIFY_DOCUMENT_RENTER:
    '/applications/public/#APPLICATION_ID#/for_renter/#RENTER_ID#/verify/',
  PUBLIC_PROPERTIES: '/properties/public/',
  AGENT_PROPERTIES: '/properties/public/listed_by/',
  PROPERTY_DESCRIPTION: '/properties/description/',
  ATTACH_DOCUMENTS_TO_APPLICATION:
    '/applications/public/#APPLICATION_ID#/for_renter/#RENTER_ID#/upload_documents/',
  SUBMIT_APPLICATION_RENTER:
    '/applications/public/#APPLICATION_ID#/for_renter/#RENTER_ID#/lock-in',
  APPLICATION_CHECKOUT_VIEW: '/applications/public/#APPLICATION_ID#/pay',
  GET_SINGLE_APPLICATION: '/applications/public/#APPLICATION_ID#/',
  GET_DOCUMENT_SIGN_SIGNING_SESSION:
    '/applications/public/#APPLICATION_ID#/for_renter/#RENTER_ID#/sign/',
  CREATE_SEND_LEASE_SESSION:
    '/applications/public/#APPLICATION_ID#/send-lease/',
  ACCEPT_APPLICATION_BY_AGENT:
    '/applications/public/#APPLICATION_ID#/for_agent/#AGENT_ID#/accept/',
  DENY_APPLICATION_BY_AGENT:
    '/applications/public/#APPLICATION_ID#/for_agent/#AGENT_ID#/deny/',
  CANCEL_APPLICATION_BY_RENTER:
    '/applications/public/#APPLICATION_ID#/for_renter/#RENTER_ID#/cancel/',
  NOTIFY_SIGN_LEASE: '/applications/public/#APPLICATION_ID#/notify_sign_lease/',
  FINIX_PAYMENT_SESSION: '/applications/public/#APPLICATION_ID#/pay/',
  FAKE_FINIX_PAYMENT_SESSION: '/applications/public/test_pay',
  TU_GENERATE_SCREENING_SESSION:
    '/applications/public/transunion/generate/#APPLICATION_ID#',
  TU_SCREENING_ANSWER_SESSION:
    '/applications/public/transunion/answer/#EXAM_ID#',
  ADD_CO_APPLICANT: `/applications/public/#APPLICATION_ID#/add_coapplicants/`,
  DELETE_CO_APPLICANT: `/applications/public/#APPLICATION_ID#/delete_coapplicant/#EMAIL#`,
  ACCEPT_CO_APPLICANT_INVITE:
    '/applications/public/application/#APPLICATION_ID#/invite/accept/',
  REJECT_CO_APPLICANT_INVITE:
    '/applications/public/application/#APPLICATION_ID#/invite/reject/',
  GET_AGENT_DOCUMENTS_PDF:
    '/applications/public/application/#APPLICATION_ID#/generate/pdf/',
  GET_AGENT_DOCUMENTS_PDF_PER_APPLICANT:
    '/applications/public/application/#APPLICATION_ID#//applicant/#APPLICANT_ID#/generate/pdf/',
  REORDER_DOCUMENTS: `/applications/public/reorder/documents/`,
  FINIX_GENERATE_APPLE_SESSION: '/applications/public/apple_pay_session',
  SKIP_LEASE: '/applications/public/#APPLICATION_ID#/manual-lease-sign/',
  GET_CRM_APPLICATIONS: '/applications/public/agent/crm/applicants/',
  REOPEN_APPLICATION:
    '/applications/public/application/#APPLICATION_ID#/reopen/',
  RESEND_EMAIL_FOR_APPLICANT:
    '/applications/public/#APPLICATION_ID#/applicant/resend_email/',
  GET_AGENT_CUSTOM_TEMPLATE_FOR_APPLICANT:
    'users/renter/application/template/#APPLICATION_ID#/',
  SAVE_RENTER_CUSTOM_TEMPLATE_FOR_APPLICANT:
    'users/renter/application/template/#APPLICATION_ID#/',
  UPDATE_APPLICATION: '/applications/public/#APPLICATION_ID#/',
  CREDIT_BYPASS:
    'applications/public/application/#APPLICATION_ID#/applicant/#APPLICANT_ID#/credit_bypass/',
  GET_RENTER_TRANSUNION_INFORMATION: 'users/renter/transunion/fetch',
  UPDATE_RENTER_TRANSUNION_INFORMATION: '/users/renter/transunion/update',
  REVERT_CREDIT_BYPASS:
    'applications/public/application/#APPLICATION_ID#/applicant/#APPLICANT_ID#/revert_credit_bypass/',
  GET_RENTER_APPLICATIONS: '/applications/public/agent/crm/#RENTER_ID#/',
  GET_NYLAS_SESSION: '/users/nylas/authorization/',
  SET_NYLAS_CODE: '/users/nylas/callback/',
  GET_NYLAS_CONNECTION: '/users/nylas/validity_check/',
  GET_MARKETING_CRM_APPLICANTS: '/users/marketing/crm/fetch',
  GET_MARKETING_CRM_ANALYTICS: '/users/marketing/crm/analytics/',
  GET_MARKETING_CRM_APPLICATIONS: '/users/marketing/crm/detail/#MAIL_USER_ID#/',
  GET_APPLICATION_CRM_ANALYTICS: '/applications/public/agent/crm/analytics',
  DELETE_NYLAS_CONNECTION: '/users/nylas/delete/',
};

const ADDRESS_API_URL = {
  ADDRESS_AUTCOMPLETE: '/properties/autocomplete/',
  OFFICE_COMMUTE: '/properties/public/distance_from/', // :property_id
  SEARCH_AUTOCOMPLETE: 'properties/autocomplete_location_search/',
};

const LANDLORD_URLS = {
  GET_PROFILE: 'users/landlords/#ID#/',
};

const AGENT_URLS = {
  GET_PROFILE: 'users/agents/#ID#/',
  AGENT_PUBLIC_PROFILE: 'users/public/profile/#ID#/',
  FETCH_TRANSACTION_HISTORY: 'users/subscription/transactions/',
  FETCH_UPCOMING_PAYMENTS: 'users/subscription/upcoming_payments/',
  UPDATE_TRANSACTION_CARD: 'users/finix_identity/update/',
  ADD_TRANSACTION_CARD: 'users/finix_identity/create/',
};

const RENTER_URLS = {
  GET_PROFILE: 'users/renters/#ID#/',
  UPDATE_PROFILE: 'users/renters/#ID#/',
  SAVE_DOCUMENTS: 'users/documents/',
  GET_DOCUMENTS: 'users/documents/',
  SIGN_FAIR_HOUSING: 'users/fairhousing/sign/',
  GET_IS_RENTER_ALREADY_SIGN_FAIR_HOUSING_FORM: 'users/fairhousing/status/',
};

const CHAT_API_URL = {
  GET_ROOMS: '/messaging/rooms/',
  GET_ROOM_MESSAGES: '/messaging/public/for_room/#ID#',
  GET_EXISTING_ROOM: '/messaging/rooms/get_room/',
  CREATE_MESSAGE: '/messaging/public/',
  CREATE_MESSAGE_ROOM: '/messaging/rooms/',
  UPDATE_ROOM: '/messaging/rooms/#ROOM_ID#/',
  GET_USER_HAS_UNREAD_MSGS: '/messaging/public/has_unreads',
  REMOVE_LINKED_PROPERTY:
    '/messaging/public/for_room/#ROOM_ID#/delete_linked_propery/#PROPERTY_ID#',
  GET_FAIR_HOUSING_PROPOSAL_EXIST:
    '/messaging/public/for_room/#ROOM_ID#/fair_housing/exists_request/',
  GET_SOCKET_AUTH: '/users/websocket/token',
};

const PASSWORD = {
  PASSWORD_RESET: '/accounts/password_reset/',
  PASSWORD_CONFIRM: '/accounts/password_reset_confirm/',
  PASSWORD_RESET_TOKEN: '/accounts/password_reset_valid_token/',
};

const VERIFY = {
  VERIFY_EMAIL: '/accounts/verify_user_valid_token/',
  VERIFY_USER_CONFIRM: '/accounts/verify_user_confirm/',
  VERIFY_EMAIL_VIEW: '/accounts/verify_user/',
};

export {
  API_BASE_URL,
  GOOGLE_MAP_URL,
  GOOGLE_PLACE_API,
  AUTH_API_URLS,
  PROPERTIES_API_URL,
  ADDRESS_API_URL,
  PLAID_URLS,
  AGENT_URLS,
  RENTER_URLS,
  LANDLORD_URLS,
  APPLICATIONS_API_URL,
  CHAT_API_URL,
  PASSWORD,
  VERIFY,
};
