const DATE_FORMATS = {
  MM_DD_YYYY: 'MM/dd/yyyy',
  BACKEND_DATE_FORMAT: 'yyyy-MM-dd',
};

const TIME_FORMATS = {
  H_MM_A: 'h:mm a',
};

export { DATE_FORMATS, TIME_FORMATS };
