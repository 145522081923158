import React from 'react';

const ReceiptPro = (props: object) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.3359 5.00033V7.01699C18.3359 8.33366 17.5026 9.16699 16.1859 9.16699H13.3359V3.34199C13.3359 2.41699 14.0943 1.66699 15.0193 1.66699C15.9276 1.67533 16.7609 2.04199 17.3609 2.64199C17.9609 3.25032 18.3359 4.08366 18.3359 5.00033Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66406 5.83366V17.5003C1.66406 18.192 2.4474 18.5837 2.9974 18.167L4.4224 17.1003C4.75573 16.8503 5.2224 16.8837 5.5224 17.1837L6.90573 18.5753C7.23073 18.9003 7.76406 18.9003 8.08906 18.5753L9.48906 17.1753C9.78073 16.8837 10.2474 16.8503 10.5724 17.1003L11.9974 18.167C12.5474 18.5753 13.3307 18.1837 13.3307 17.5003V3.33366C13.3307 2.41699 14.0807 1.66699 14.9974 1.66699H5.83073H4.9974C2.4974 1.66699 1.66406 3.15866 1.66406 5.00033V5.83366Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 10.8418H10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 7.50879H10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99609 10.833H5.00358"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99609 7.5H5.00358"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReceiptPro;
