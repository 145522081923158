const ERROR_MESSAGES = {
  otpNotValid: 'The OTP entered is not valid. Please try again.',
  internalError:
    'Something went wrong. Please contact support at hey@zeme.io and describe your issue.',
  emailExist: 'Email already exist.',
  phoneExist: 'Phone Number already exist.',
  FILE_SIZE_EXCEED: `The following file(s) exceed the maximum size limit of #FILE_SIZE#MB: #FILE_NAMES#. Please upload files smaller than #FILE_SIZE#MB.`,
};

const AUTH_MSG = {
  sessionExpired: 'Session expired',
  loginSuccess: 'You are successfully logged in.',
  logoutSuccess: 'You have been logged out successfully.',
  deleteSuccess: 'Your account has been successfully deleted.',
  subscribeSuccess:
    'You have successfully subscribed to the plan. Thank you for choosing our service!',
  cardUpdatedSuccess:
    'Your card details have been successfully updated. Thank you for keeping your information up to date!',
  subscriptionCancelled:
    'Your subscription has been successfully cancelled. Thank you for being a part of our service.',
};

const PROPERTY_MSG = {
  closeSuccess: 'Property closed successfully',
  openSuccess: 'Property opened successfully',
};

const VALIDATION_MESSAGES = {
  required: '*Required.',
  validEmail: 'Please enter a valid email address.',
  firstNameCharacterLimit: 'First Name must be at least 2 characters long',
  lastNameCharacterLimit: 'Last Name must be at least 2 characters long',
  termsRequired: 'Please accept the terms.',
  accuracyRequired: 'please provide your confirmation.',
  password: 'Password must be at least 8 characters',
  passwordUpperChar: 'Password must contain at least one uppercase letter',
  passwordNumberChar: 'Password must contain at least one number',
  passwordSpecialChar: 'Password must contain at least one special character',
  confirmPassword: 'Confirm password must be matched with password.',
  onlyDigits: 'Phone number must only contain digits',
  validPhone: 'Phone number must be valid.',
  maxTenDigits: 'Phone number can have at most 10 digits',
  maxElevenDigits: 'Phone number can have at most 11 digits',
  timeFormat: 'Invalid time. Please enter a time between 00:00 and 12:00.',
  UtilityOne: 'At least one utility is required',
  creditMsg:
    'Ensure this value is greater than or equal to 300 or less than or equal to 850',
  onlyNumber: 'Please enter valid numbers',
  fileRequired: 'Atleast one file is required.',
  nNumberOfFilesRequired: 'Please select at least five files to proceed.',
  requiredRequestedDocuments:
    'Please provide all requested documents by agent.',
  ageValidation: 'You must be at least 18 years old.',
  otpValidation: 'Please enter a valid OTP.',
  valueRequired: 'Value is required',
  creditCheckTemplateStepRequired: `Please complete all other steps before initiating the Credit Check.`,
  templateAllOtherStepsRequired: `Please complete all other steps before initiating the Template Form.`,
  selectAtLeastOnePet: 'Please select atleast one pet.',
  coApplicantEmailAlreadyExist:
    'You have already invited this user to this application, it might take a few minutes to receive this invite.',
  appleEmailNotFound: `We weren't able to find an email associated with your account. To proceed, please use the regular registration.`,
  applePrivateEmail: `Uh-oh! It seems you're using a masked Apple email address, which isn't supported for signups. To continue, please use the regular registration.`,
  invalidCharacters:
    'This field can only contain letters, numbers, hyphens, single quotes, commas, periods, and spaces',
  firstNameMaxLength: 'First Name cannot exceed 50 characters',
  lastNameMaxLength: 'Last Name cannot exceed 50 characters',
  middleNameMaxLength: 'Middle Name cannot exceed 50 characters',
  middleNameCharacterLimit: 'Middle Name must be at least 2 characters long',
  companyAddressLineTwoInvalidCharacters:
    "Invalid character. Only letters, numbers, spaces, and these symbols are allowed: #, (), &, ',', ., ', -, _, +, ~, /, *.",
};

const SUCCESS_MESSAGES = {
  createdMessage: 'Successfully created.',
  successfulRegister:
    'Registration successful. Please verify your email before logging in.',
  updatedMessage: 'Successfully updated',
  signNotificationSent: 'Notification sent successfully to the renter.',
  applicationAccepted: 'Application accepted successfully.',
  applicationRejected: 'Application rejected successfully.',
  otpVerifiedSuccessfully: 'OTP verified successfully.',
  LINK_COPIED: 'Link copied successfully',
  DOCUMENT_UNLINKED: 'Document(s) unlinked successfully',
  CREDIT_BYPASS: 'Credit bypassed successfully',
  REVERT_CREDIT_BYPASS: 'Credit bypass reverted successfully',
  NYLAS_ACCOUNT_LINKED: 'Zeme AI CRM successfully linked.',
};

const PRICING_MSG = {
  NO_PAYMENT_METHOD_FOUND: `Please provide a payment method to upload the property.`,
  UPDATE_PAYMENT_METHOD_MSG: `Your previous payment method failed. Please update your payment details.`,
  ADD_PAYMENT_METHOD: 'Add Payment Method',
  UPDATE_PAYMENT_METHOD: 'Update Payment Method',
  REOPEN_PROPERTY_WITH_PRICING: `30 days have passed since last payment for this property. Re-opening this property will result in a #PRICE# charge.`,
  REOPEN_PROPERTY_WITH_PRICING_WITH_FREE_PROPERTY: `Re-opening this property will result in a #PRICE# charge.`,
  REOPEN_APPLICATION_AND_PROPERTY:
    'To reopen this application you must reopen this property.',
  REOPEN_APPLICATION: 'Do you want to reopen this application?',
  REOPEN_APPLICATION_WITH_PRICING:
    'To reopen this application you must reopen this property. Reopening this property will result in a #PRICE# charge.',
  REOPEN_APPLICATION_SUCCESS: 'Application reopened successfully',
};

export {
  VALIDATION_MESSAGES,
  AUTH_MSG,
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  PROPERTY_MSG,
  PRICING_MSG,
};
