'use client';

import React from 'react';
import { clsx } from 'clsx';

// Import types
import type {
  buttonVariantsType,
  sizesType,
  buttonComponentType,
} from '@src/interfaces/components/coreUI/button';

/**
 *
 * @param props
 * @returns HTML Node
 */
const Button = ({
  type = 'button',
  variant = 'primary',
  size = 'lg',
  children = '',
  extraClasses = '',
  disabled = false,
  onClick = () => {
    return;
  },
  buttonProps = {},
}: buttonComponentType) => {
  // Button variants classes
  const variants: buttonVariantsType = {
    primary:
      'text-white bg-gradient-to-r from-primary-dark to-primary-light hover:bg-gradient-to-br active:bg-gradient-to-br',
    primaryOutline:
      'text-primary-dark border border-primary-dark hover:text-primary-light hover:border-primary-light active:text-primary-light active:border-primary-light',
    primaryWhite:
      'text-primary-dark bg-white hover:bg-white/90 hover:text-primary-light active:text-primary-light to-secondary-light',
    secondary:
      'text-white bg-gradient-to-r from-secondary-dark to-secondary-light hover:bg-gradient-to-br active:bg-gradient-to-br',
    secondaryOutline:
      'text-secondary-dark border border-secondary-dark hover:text-secondary-light hover:border-secondary-light active:text-secondary-light active:border-secondary-light',
    secondaryWhite:
      'text-secondary-dark bg-white hover:bg-white/90 hover:text-secondary-light active:text-secondary-light to-secondary-light',
    whiteOutline:
      'text-white border border-white hover:opacity-75 active:opacity-75',
    blue: 'text-white bg-gradient-to-r from-blue-dark to-blue-light hover:bg-gradient-to-br active:bg-gradient-to-br',
    blueOutline:
      'text-blue-dark border border-blue-dark hover:text-blue-light hover:border-blue-light active:text-blue-light active:border-blue-light',
    pink: 'text-white bg-gradient-to-r from-pink-dark to-pink-light hover:bg-gradient-to-br active:bg-gradient-to-br',
    pinkOutline:
      'text-pink-dark border border-pink-dark hover:text-pink-light hover:border-pink-light active:text-pink-light active:border-pink-light',
    danger: 'text-white bg-danger hover:bg-danger/80 active:bg-danger/80',
    dangerOutline:
      'text-danger border border-danger hover:text-danger/80 hover:border-danger/80 active:text-danger/80 active:border-danger/80',
  };

  // Button different sizes
  const sizes: sizesType = {
    lg: 'px-4 py-4 min-w-btnlg text-lg',
    md: 'px-3 py-3 min-w-btnmd text-sm',
    mdTwo: 'px-3 py-3.5 min-w-btnmd text-sm',
    sm: 'px-3 py-3 min-w-btnsm text-sm',
    smTwo: 'px-3 py-3 min-w-smtwo text-sm',
    thumbButton: 'px-3 py-3 min-w-thumbbutton text-xs',
  };

  // Combine classes
  const className = clsx(
    {
      'flex justify-center text-center rounded-full font-medium focus:outline-none focus-visible:outline-none':
        true,
      'disabled:opacity-50': disabled,
    },
    variants[variant as keyof buttonVariantsType],
    sizes[size as keyof sizesType],
    extraClasses
  );

  return (
    <button
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

export default Button;
